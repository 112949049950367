.popup {
  visibility: hidden;
  display: flex;
  position: fixed;
  width: 100%;
  height: 100vh;
  bottom: 0;
  left: 0;
  transition: 0.4s linear;
  z-index: -1;
  /* opacity: 0; */
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.popup_open {
  background-color: var(--background-overlay-color);
  /* opacity: 1; */
  visibility: visible;
  z-index: 1;
  touch-action: none;
}

.popup__container {
  width: 100%;
  height: 83vh;
  max-width: 425px;
  padding: 42px 8px;
  box-sizing: border-box;
  margin: auto auto 0;
  border-radius: 16px 16px 0 0;
  /* opacity: 0; */
  background-color: var(--frame-color);
  transition: 0.3s linear;
  touch-action: none;
  z-index: -1;
  /* animation: openPopup 0.3s forwards ease-out; */

  &::before {
    content: "";
    width: 32px;
    height: 4px;
    display: block;
    background-color: black;
    border-radius: 6px;
    z-index: 3;
    margin: -20px auto 16px;
  }
}

.popup__container_open {
  /* opacity: 1; */
  visibility: visible;
  z-index: 1;
  touch-action: none;
}

@keyframes openPopup {
  from {
    transform: translateY(83vh);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes closePopup {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 83vh);
  }
}
