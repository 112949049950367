.group-profile {
  width: 95%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 47px auto;
  max-width: 404px;
  box-sizing: border-box;
  justify-content: space-between;

  &__about-me {
    min-height: 180px;
    width: 100%;
    background-color: var(--frame-color);
    /*   mask: url(../../images/profile/profileMask.png);
    mask-position: center;
    mask-size: 100% 100%; */
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    margin: -40px auto 16px;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  }

  &__photo-container {
    margin: 0 auto 0;
    width: 25%;
    background-color: var(--frame-color);
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
    height: 100%;
    border-radius: 50%;
    border: 2px solid #000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &:before {
      content: "";
      display: block;
      padding-top: 100%; /* initial ratio of 1:1*/
    }
  }

  &__photo {
    margin: auto;
    max-width: 112px;
    max-height: 56px;
    margin: 0 auto;
    background-size: cover;
  }

  &__name {
    font-size: 20px;
    line-height: 20px;
    margin: 47px auto 0;
    max-width: 95%;
  }

  &__description {
    margin: 12px auto;
    width: 95%;
  }

  &__followers-number {
    margin: auto auto 12px;
    padding: 0;
    width: 95%;
    text-align: left;
  }

  &__group-id {
    margin: auto auto 12px;
    padding: 0;
    width: 95%;
    text-align: left;
  }

  &__group-menu-button {
    width: 100%;
    height: 52px;
    margin: 0 auto;
    background-color: var(--accent-color-blue);
    color: var(--text-accent-color);
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: 12px;
    padding: 12px 8px;
    cursor: pointer;
    transition: 0.2s linear;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__group-menu-status-arrow {
    margin: auto 0;
    height: 16px;
    width: 16px;
    transition: 0.2s linear;

    &_open {
      transform: rotate(90deg);
    }
  }

  &__group-select-button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: #4ccfff;
    color: #fff;
    padding: 0;
    border-radius: 12px;
    margin: 32px auto 0;
    transition: opacity 0.3s linear;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }
  }

  .group-profile__participants {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 32px auto 0;

    &-title {
      width: 100%;
      height: 50px;
      border: none;
      margin: 0 auto;
      background-color: var(--accent-color-blue);
      color: #fff;
      display: flex;
      border-radius: 12px;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }

    &-list {
      display: flex;
      flex-direction: column;
      margin: 8px auto 0;
      width: 95%;
      max-height: 352px;
      overflow-y: auto;
      padding: 0;

      &-item {
        width: 100%;
        height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: start;
        background-color: var(--frame-color);
        margin-bottom: 8px;
        border-radius: 12px;
        padding: 8px;
        box-sizing: border-box;

        &:last-child {
          margin-bottom: 0;
        }

        &-avatar {
          width: 48px;
          height: 48px;
          object-fit: cover;
          border-radius: 8px;
          margin: auto 0;
        }

        &-name {
          font-size: 16px;
          font-weight: 500;
          line-height: 18.75px;
          text-align: left;
          margin: auto auto auto 8px;
        }
      }
    }
  }

  &__events-block {
    background-color: var(--frame-color);
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
    padding: 12px 8px;
    border-radius: 12px;
    min-height: 80px;
    width: 100%;
    box-sizing: border-box;

    &-link {
      padding: 0;
      display: flex;
      flex-direction: row;
      text-decoration: none;
      justify-content: space-between;
      width: 100%;
      background: none;
      border: none;
      color: var(--text-color);
    }
  }
}

.block-title {
  margin: auto 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
