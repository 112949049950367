.log-reg-form {
  margin: 70px auto 0;
  display: flex;
  flex-direction: column;
  width: 95%;
  min-height: 575px;
  max-width: 425px;
}

.log-reg-form__logo {
  height: 30px;
  padding-left: 15px;
  transition: opacity 0.3s linear;
}

.log-reg-form__logo-pic {
  height: 100%;
}

.log-reg-form__logo:hover {
  opacity: 0.7;
}

.log-reg-form__title-container {
  display: flex;
  flex-direction: row;
  max-height: 38px;
  margin: 40px 0 0;
}

.log-reg-form__back-button {
  background: none;
  border: none;
  width: 28px;
  height: 28px;
  padding: 0;
  margin: auto 5px auto 0;
  transition: 0.2s linear;
}

.log-reg-form__back-button:hover {
  opacity: 0.7;
}

.log-reg-form__title {
  margin: 0;
  padding: 0;
  text-align: left;
  color: var(--text-color);
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 38px;
  letter-spacing: 0px;
}

.log-reg-form__nav-container {
  margin: 20px auto 30px;
  width: 100%;
  background-color: var(--frame-color);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  height: 38px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.log-reg-form__nav-button {
  text-decoration: none;
  color: var(--text-color);
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 50%;
  border-radius: 12px;
  transition: 0.2s ease-in;
}

.log-reg-form__nav-button:hover {
  box-shadow: 0px 0px 0px 2px var(--accent-hover-color-crimson);
  color: var(--accent-hover-color-crimson);
  background-color: #fff;
}

.log-reg-form__nav-button-text {
  margin: auto;
}

.log-reg-form__button {
  margin: 15px auto 0;
  padding: 0;
  width: 100%;
  background-color: var(--accent-color-blue);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  color: var(--text-accent-color);
  border: none;
  border-radius: 12px;
  min-height: 50px;
  text-align: center;
  font-family: "Inter", Arial, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: opacity 0.2s ease-in;
  cursor: pointer;
}

.log-reg-form__server-error {
  margin: auto auto 0;
  color: var(--text-error-color);
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: center;
}

.log-reg-form__button:disabled {
  background-color: #f8f8f8;
  color: #c2c2c2;
  cursor: default;
  opacity: 1;
}

.log-reg-form__button:disabled + .log-reg-form__button:hover {
  background-color: #f8f8f8;
  color: #c2c2c2;
  cursor: default;
  opacity: 1;
}

.log-reg-form__button:hover {
  opacity: 0.8;
}
