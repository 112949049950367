.calendar-block {
  margin: 20px 0 0;
  background-color: var(--frame-color);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  width: 47%;
  min-width: 144px;
  max-width: 200px;
  max-height: 200px;
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
  padding: 8px;
}

.calendar-block__link {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  background: none;
  border: none;
  margin: 0 auto 14px;
  padding: 0;
  color: var(--text-color);
}

.calendar-block__link-arrow {
  margin: auto 0;
}
