.header {
  margin: 64px auto 0;
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  max-width: 1280px;
  height: 38px;
  align-items: center;
  max-width: 404px;
}

.header__title {
  margin: auto 0;
  font-family: "Exo 2";
  color: var(--text-color);
  font-size: 32px;
  font-weight: 900;
  line-height: 28px;
}

.header__menu-button {
  background: none;
  border: none;
  padding: 0;
  height: 32px;
  width: 32px;
}

.header__buttons-container {
  display: flex;
}

.header__button {
  margin: auto 0;
  background: none;
  color: var(--text-color);
  border: none;
  font-size: 18px;
  padding: 15px;
  cursor: pointer;
  transition: 0.2s;
}

.header__button:hover {
  opacity: 0.8;
}
