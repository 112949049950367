.log-reg-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.log-reg-input__title {
  margin: 0;
  color: #a0a0a0;
  font-family: "Inter", Arial, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.log-reg-input__input {
  border: none;
  background-color: #f9f9f9;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  height: 50px;
  margin: 10px 0 0;
  box-sizing: border-box;
  padding: 16px 8px;
  color: #000;
  font-family: "Inter", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  caret-color: var(--accent-color-crimson);
  border: none;
  outline: none;
}

.log-reg-input__input_error {
  outline: var(--text-error-color) solid 1.5px;
}

.log-reg-input__input:focus {
  box-shadow: 0px 0px 0px 1px var(--accent-color-crimson);
}

.log-reg-input__input::placeholder {
  color: rgb(160, 160, 160);
  font-family: "Inter", Arial, sans-serif;
}

.log-reg-input__error-message {
  margin: 10px 0 0;
  color: var(--text-error-color);
  font-family: "Inter", Arial, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0;
  transition: opacity 0.3s linear;
  /* min-height: 24px; */
}

.log-reg-input__error-message-active {
  opacity: 1;
}
