.search-string {
  width: 100%;
  padding: 8px 8px 8px 44px;
  margin: 0;
  height: 46px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  background-image: url(../../images/searchString/searchIcon.svg);
  background-repeat: no-repeat;
  background-position: 12px center;
  background-size: 17px;
  border: none;
  border-radius: 8px;
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:focus {
    border: none;
    outline: 2px solid var(--accent-color-crimson);
  }
  &::placeholder {
    color: #9c9c9c;
    height: 28px;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
