.profile {
  width: 95%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 47px auto;
  max-width: 404px;
  box-sizing: border-box;
  justify-content: space-between;
}

.block-title {
  margin: auto 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.profile__about-me {
  min-height: 180px;
  width: 100%;
  background-color: var(--frame-color);
  /*   mask: url(../../images/profile/profileMask.png);
  mask-position: center;
  mask-size: 100% 100%; */
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin: -40px auto 16px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
}

.profile__photo-container {
  margin: 0 auto 0;
  width: 25%;
  background-color: var(--frame-color);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  height: 100%;
  border-radius: 50%;
  border: 2px solid #000;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.profile__photo-container:before {
  content: "";
  display: block;
  padding-top: 100%; /* initial ratio of 1:1*/
}

.profile__photo {
  margin: auto;
  max-width: 112px;
  max-height: 56px;
  margin: 0 auto;
  background-size: cover;
}

.profile__name {
  font-size: 20px;
  line-height: 20px;
  margin: 47px auto 0;
  max-width: 95%;
}

.profile__my-description {
  margin: 12px auto;
  width: 95%;
}

.profile__group-menu-button {
  width: 100%;
  height: 52px;
  margin: 0 auto;
  background-color: var(--accent-color-blue);
  color: var(--text-accent-color);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 12px;
  padding: 12px 8px;
  cursor: pointer;
  transition: 0.2s linear;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile__group-menu-status-arrow {
  margin: auto 0;
  height: 16px;
  width: 16px;
  transition: 0.2s linear;
}

.profile__group-menu-status-arrow_open {
  transform: rotate(90deg);
}

.profile__events-block {
  background-color: var(--frame-color);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.15);
  padding: 12px 8px;
  border-radius: 12px;
  min-height: 80px;
  width: 100%;
  box-sizing: border-box;
}

.profile__events-block-link{
  padding: 0;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  justify-content: space-between;
  width: 100%;
  background: none;
  border: none;
  color: var(--text-color);
}