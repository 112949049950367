.intro{
  margin: 0 auto;
  min-height: 600px; /*delete after filling content*/
  width: 100%;
  background: radial-gradient(circle 300px at 25% 50%, rgba(123, 164, 154, 0.3), rgba(4, 9, 20, 1));
}

.intro__text-content{
  width: 45%;
  height: 450px;
  margin-left: 5%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.intro__container{
  display: flex;
  flex-direction: row;
  max-width: 1280px;
  margin: 0 auto;
}

.intro__title{
  color: #fff;
  align-content: start;
  font-size: 48px;
  margin: 0;
}
.intro__interative-name{
  color: #fff;
  align-content: start;
  font-size: 32px;
  margin: 10px 0 0;
}
.intro__interative-description{
  margin: 40px 0 0;
  color: #fff;
  align-content: start;
  font-size: 16px;
}

.intro__interative-data{
  padding: 0;
  margin: 50px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 90%;
}

.intro__statBox{
  display: flex;
  flex-direction: column;
  list-style: none;
  max-width: 143px;
}

.intro__statData{
  color: #fff;
  font-size: 46px;
  line-height: 46px;
  margin: 0;
  text-align: center;
  font-weight: bold;
}

.intro__statName{
  color: #fff;
  font-size: 16px;
  margin: 20px 0 0;
  max-width: 100px;
  text-align: center;
}

.intro__interative-location{
  color: #fff;
  align-content: start;
  margin: auto 0 0;
  font-size: 12px;
}

.intro__interative-location_bold{
  font-weight: bold;
  display: block;
}

.intro__picture-content{
  height: 480px;
  width:  45%;
  max-width: 500px;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 70px);
  grid-auto-rows: auto;
  column-gap: 10px;
  justify-items: center;
  margin: 50px 0 0;
  justify-content: center;
}
.intro__picture-cage{
  width: 100%;
  max-width: 150px;
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: auto;
  
}

.intro__picture-cage:nth-child(-1n + 3){
  grid-column: span 2;
}

.intro__picture-cage:nth-last-child(2) {
  grid-row-start: 2;
  grid-column: 2 / span 2;
}
.intro__picture-cage:nth-last-child(1) {
  grid-row-start: 2;
  grid-column: 4 / span 2;
}

.intro__picture{
  width: 100%;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(123, 164, 154, 0.3);
}