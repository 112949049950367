@import url(./vendor/fonts/fonts.css);
@import url(./vendor/normalize.css);

:root {
  --background-color: #f6f6f6;
  --background-overlay-color: rgba(0, 0, 0, 0.5);
  --frame-color: #fff;
  --frame-accent--unselected-color: #adadad;
  --frame-accent-selected-color: #696969;
  --text-color: #000;
  --frame-text-color: #fff;
  --accent-color-crimson: #c04476;
  --accent-color-blue: #03a2db;
  --accent-color-crimson-unselected: #bf869d;
  --accent-color-crimson-selected: #c04476;
  --accent-hover-color-crimson: #c193a6;
  --accent-hover-background-color: #fff;
  --text-accent-color: #fff;
  --text-error-color: #ff8181;
  /*   --backbround-color: #fff;
  --background-overlay-color: rgba(0, 0, 0, 0.5);
  --frame-color: #d9d9d9;
  --frame-accent--unselected-color: #adadad;
  --frame-accent-selected-color: #696969;
  --text-color: #000;
  --accent-color: #6727ff;
  --accent-color-unselected: #bf869d;
  --accent-hover-color: #8247ff;
  --accent-hover-background-color: #fff;
  --text-accent-color: #fff;
  --text-error-color: #ff8181; */
}

body {
  background-color: var(--background-color);
  margin: 0;
  font-family: "Exo 2";
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: "Exo2", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
