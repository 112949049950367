.event-list__items {
  padding: 0;
  margin: 9px auto 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.event-list__item {
  list-style: none;
  padding: 0;
  margin: 0;
}

.event-list__item-input {
  display: none;
  &:checked + label {
    background-color: var(--accent-hover-color-crimson);
    color: var(--text-accent-color);
  }
}

.event-list__item-button {
  display: inline-block;
  margin: 8px 8px 0 auto;
  height: 34px;
  padding: 8px;
  background-color: var(--accent-color-crimson-unselected);
  border-radius: 8px;
  transition: all 0.2s ease-out;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid var(--text-color);
  color: var(--text-accent-color);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: center;
}

.event-list__item-button_checked {
  background-color: var(--accent-color-crimson-selected);
}

.event-list__item-title {
  display: block;
  margin: auto;

  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 10px;
  text-wrap: nowrap;
}
